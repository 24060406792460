<template>
  <v-footer
    color="appbar darken-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        class="appbar darken-2 py-4 text-center white--text"
        cols="12"
      >
        Copyright © 2013 - {{ new Date().getFullYear() }} — <strong>Prime Tech Solutions</strong>  All rights reserved
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

}
</script>

<style>

</style>