<template>
  <!-- App.vue -->
  <div>
    <v-app v-if="!isLink">
      <NavigationDrawer />
      <AppBar />
      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- If using vue-router -->
          <router-view></router-view>
        </v-container>
      </v-main>

      <Footer />
    </v-app>
    <v-app v-else>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import AppBar from "./components/AppBar.vue";
import NavigationDrawer from "./components/NavigationDrawer.vue";

export default {
  name: "App",

  components: {
    Footer,
    AppBar,
    NavigationDrawer,
  },
  computed: {
    isLink() {
      return this.$route.name === "Link";
    },
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>


main {
  padding-top: 100px !important;
}

.uppercase{
  text-transform: uppercase;
}

@media (max-width: 768px) {
  main {
    padding-top: 80px !important;
  }
}
</style>