import Vue from 'vue';
import Router from 'vue-router';
import goTo from 'vuetify/es5/services/goto';

Vue.use(Router)

export default new Router({
  scrollBehavior: (to) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    }

    return goTo(scrollTo, { offset: 80 })
  },

  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/',
      name: 'links',
      component: () => import('./views/Links.vue')
    },
  ]
})
