<template>
  <v-app-bar app flat height="100" color="appbar darken-2" v-if="$vuetify.breakpoint.lgAndUp">
    <v-row alien="center">
      <v-col>
        <v-img max-height="80" max-width="160" src="../assets/logo.svg"></v-img>
      </v-col>
      <v-col class="text-right pt-5">
        <v-btn text class="white--text mx-1 scrollactive-item" depressed v-for="item in items" :key="item.title" link
          :to="item.route" :href="item.url" :target="item.target">
          {{ item.title }}
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  data: () => ({
    isActive: false,
    items: [
      { title: "Home", route: "/" },
      { title: "Services", route: "#services" },
      { title: "Portfolio", route: "#portfolio" },
      { title: "Contact", route: "#contact" },
      {
        title: "Gift Shop",
        url: "https://www.teepublic.com/user/prime-tech",
        target: "_blank",
      }
    ],
  }),
});
</script>

<style>
</style>
