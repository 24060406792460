<template>
  <div>
    <v-row align="center" justify="center">
      <v-img src="../assets/logo.svg" max-width="120" max-height="80"></v-img>
    </v-row>
    <v-app-bar
      app
      clipped-left
      collapse-on-scroll
      flat
      height="80"
      color="appbar darken-2"
    >
      <v-app-bar-nav-icon class="white--text" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      color="appbar"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-list dense flat>
        <v-list-item class="appbar darken-2">
          <v-list-item-content>
            <v-list-item-title class="pt-6 pb-6">
              <v-row align="center" justify="center">
                <v-img
                  src="../assets/logo.svg"
                  max-width="150"
                  max-height="80"
                ></v-img>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
          :href="item.url"
          :target="item.target"
        >
          <v-list-item-content class="white--text text-center">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  data: () => ({
    drawer: false,
    items: [
      { title: "Home", route: "/" },
      { title: "Services", route: "#services" },
      { title: "Portfolio", route: "#portfolio" },
      { title: "Contact", route: "#contact" },
            {
        title: "Gift Shop",
        url: "https://www.teepublic.com/user/prime-tech",
        target: "_blank",
      }
    ],
  }),
});
</script>>

<style scoped>
.v-navigation-drawer .v-list:not(.v-select-list) {
padding: 0;
}
</style>